import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';

$(document).ready(() => {
    const lightbox = new PhotoSwipeLightbox({
        gallery: '[data-image-gallery]',
        childSelector: "a:not(.d-none)",
        showHideAnimationType: 'zoom',
        pswpModule: PhotoSwipe
    });
    lightbox.init();
});
