document.addEventListener("DOMContentLoaded", function () {
    const optionTypeSelector = document.querySelectorAll(".selection-items");
    for (var i = 0; i < optionTypeSelector.length; i++) {
        optionTypeSelector[i].addEventListener("click", onSelection);
    }
    selectFirstVariant();
});

function selectFirstVariant() {
    const firstVariant = document.querySelector("[data-option-index]");
    if (firstVariant) {
        setTimeout(() => { firstVariant.click(); }, 1);
    }
}

function onSelection(event) {
    document.getElementById(`selected-${event.target.name}`).innerText = event.target.dataset.presentation;

    const optionIndex = event.target.attributes["data-option-index"].value;
    const nextType = document.querySelector(`[data-option-index="${parseInt(optionIndex, 10) + 1}"]`);
    if (nextType) {
        updateOptions(nextType.name, optionIndex);
    }
    selectVariant();
}

function currentSelection() {
    let i = 0;
    const selectionArr = [];
    while (document.querySelector(`[data-option-index="${i}"]`)) {
        selectionArr.push(parseInt(document.querySelector(`[data-option-index="${i}"]:checked`).value, 10));
        i += 1;
    }
    return selectionArr;
}

function nextOptionValues(optionIndex) {
    const values = [];
    const variantOptionsTargets = document.querySelectorAll('.product-variants__list > li > input');
    variantOptionsTargets.forEach((option) => {
        const optionValueIds = JSON.parse(option.attributes["data-option-value-ids"].value);
        const selectedOptionIds = currentSelection();
        let matched = true;
        for (let i = 0; i <= optionIndex; i += 1) {
            if (optionValueIds[i] !== selectedOptionIds[i]) {
                matched = false;
                break;
            }
        }
        if (matched) {
            values.push(optionValueIds[parseInt(optionIndex, 10) + 1]);
        }
    });
    return values;
}

function updateOptions(nextTypeName, optionIndex) {
    const optionValues = nextOptionValues(optionIndex);

    let firstRadio = null;
    const allNextOptions = [...document.querySelectorAll(`[name="${nextTypeName}"]`)];
    allNextOptions.forEach((radio) => {
        if (!optionValues.includes(parseInt(radio.value, 10))) {
            radio.disabled = true;
            radio.parentElement.classList.add("d-none");
        } else {
            radio.disabled = false;
            radio.parentElement.classList.remove("d-none");
            if (!firstRadio) { firstRadio = radio; }
        }
    });

    const nextSelectedRadio = document.querySelector(`[name="${nextTypeName}"]:checked`);
    if (nextSelectedRadio.disabled) {
        firstRadio.click();
    } else {
        nextSelectedRadio.click();
    }
}

function updateView(variant) {
    document.querySelector('#product-price .price.selling').innerHTML = variant.dataset.price;
    showVariantImages(variant.value);
    showVariantProperties(variant.value);
}

function showVariantProperties(variantId) {
    $(".vprop").addClass('d-none');
    $(".prop-" + variantId).removeClass('d-none');
}

function showVariantImages(variantId) {
    $(".vtmb").addClass('d-none');
    $(".tmb-" + variantId).removeClass('d-none');
    const currentThumb = $("#" + $("#main-image").data("selectedThumbId"));
    if (!currentThumb.hasClass("vtmb-" + variantId)) {
        let thumb = $($("#product-images .thumbnails a:visible.vtmb").eq(0));
        if (!(thumb.length > 0)) {
            thumb = $($("#product-images .thumbnails a:visible").eq(0));
        }
        const newImg = thumb.find("a").attr("href");
        $("#product-images .thumbnails a").removeClass("selected");
        thumb.addClass("selected");
        $("#main-image img").attr("src", newImg);
        $("#main-image").data("selectedThumb", newImg);
        $("#main-image").data("selectedThumbId", thumb.attr("id"));
    }
}

function updateVariantAvailability() {
    const variantAvailability = document.querySelector(`#variant_availability_${this.variant.value}`);
    if (variantAvailability) {
        [...document.querySelectorAll("[data-variant-availability]")].forEach((availabilityBlock) => {
            availabilityBlock.classList.add("d-none");
        });
        variantAvailability.classList.remove("d-none")
    }
}

function selectVariant() {
    this.variant = document.querySelector(`[data-option-value-ids="${JSON.stringify(currentSelection())}"]`);
    if (this.variant) {
        this.variant.checked = true;
        updateVariantAvailability();
        updateView(this.variant);
    } else {
        this.priceTarget.innerText = "Not found, please select all optionTypeSelector";
    }
}