// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./product_selection_component";
import "./change_quantity";
import "./product_note_updater";
import "./submit_on_select";
import "./image_gallery";
import "popper.js";
import "bootstrap";

ActiveStorage.start()
