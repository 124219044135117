$(document).ready(() => {
    const saveProductNote = (form, id, line_item) => {
        const data = {
            order_token: Spree.current_order_token,
            line_item,
        };
        return Spree.ajax({
            method: 'PATCH',
            url: Spree.pathFor('api/checkouts/' + Spree.current_order_id + '/line_items/' + id),
            data: JSON.stringify(data),
            headers: { 'X-Spree-Order-Token': Spree.current_order_token },
            contentType: "application/json"
        });
    }
    $('[data-save-note-async]').on('change', el => {
        const dataset = el.currentTarget.dataset;
        const $input = $(el.currentTarget);
        const form = $input.closest('form');
        $input.prop('disabled', true);
        const request = saveProductNote(form, dataset.itemId, { options: {note: el.currentTarget.value}, quantity: dataset.qty });
        request.done(() => {
            $input.prop('disabled', false);
        });
        request.fail(() => {
            $input.prop('disabled', false);
        });
    });
});
