$(document).ready(() => {
    const updateQty = (form, quantity, id) => {
        var data = {
            order_token: Spree.current_order_token,
            quantity,
        };
        return Spree.ajax({
            method: 'PATCH',
            url: Spree.pathFor('api/checkouts/' + Spree.current_order_id + '/line_items/' + id),
            data: JSON.stringify(data),
            headers: { 'X-Spree-Order-Token': Spree.current_order_token },
            contentType: "application/json"
        });
    }
    $('[data-save-qty-async]').on('change', el => {
        const dataset = el.currentTarget.dataset;
        const $input = $(el.currentTarget);
        const form = $input.closest('form');
        const inputs = $input.parent().find('input');
        inputs.prop('disabled', true);
        const request = updateQty(form, el.currentTarget.value, dataset.itemId);
        request.done(() => {
            // inputs.prop('disabled', false);
            window.location.href = '#line_item_' + dataset.itemId;
            window.location.reload(true);
        });
        request.fail(() => {
            inputs.prop('disabled', false);
        });
    });
    $('[data-change-qty]').on('click', el => {
        const dataset = el.currentTarget.dataset;
        const $input = $(`#${dataset.changeQty}`);
        const previousValue = parseInt($input.val(), 10);
        if (dataset.changeBy === '-1' && previousValue === 1) {
            return;
        }
        const newValue = previousValue + parseInt(dataset.changeBy, 10);
        $input.val(newValue);
        const form = $input.closest('form');
        if (form && dataset.itemId) {
            const inputs = $input.parent().find('input');
            inputs.prop('disabled', true);
            const request = updateQty(form, newValue, dataset.itemId);
            request.done(() => {
                window.location.href = '#line_item_' + dataset.itemId;
                window.location.reload(true);
            });
            request.fail(() => {
                inputs.prop('disabled', false);
                $input.val(previousValue);
            });
        }
    });
});
